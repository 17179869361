<template>
    <div style="margin:0 10px">
        <router-view></router-view>
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <div style="width:160px;margin-right:10px;">
                        <el-input type="text" v-model="query.typeCode" placeholder="类型编码"></el-input>  
                    </div>
                    <el-button type="primary">查询</el-button>
                    <el-button type="primary" @click="$router.push({name:'dict_add'})">+ 添加</el-button>    
                </div>

                <div><el-button type="text" @click="$router.push({name:'dict_type'})">字典类型</el-button></div>
            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    :data="tableData"
                    style="width: 100%;"
                    v-loading="loading"
                    element-loading-background="rgba(255,255,255, 0.6)"
                    :header-cell-style="{background:'#f8f8f9',color:'#606266',borderBottom:'1px solid #e8eaec',padding:'8px 0',fontWeight:'700'}"
                >
                    <el-table-column prop="dictCode" label="数据编码" align="center"></el-table-column>
                    <el-table-column prop="dictValue" label="数据值" align="center"></el-table-column>
                    <el-table-column prop="typeCode" label="类型编码" align="center"></el-table-column>
                    <el-table-column prop="sort" label="排序" align="center"></el-table-column>
                    <el-table-column prop="" label="操作" align="center" width="180">
                        <template slot-scope="scope">
                            <el-button @click="$router.push({name:'dict_edit',query:scope.row})" type="text" size="small">编辑</el-button>
                            <el-popconfirm
                                    title="是否确定删除？"
                                    confirm-button-text="确认删除"
                                    @confirm="handleDel(scope.row)"
                                >
                                    <el-button style="margin-left: 10px;" slot="reference" type="text">删除</el-button>
                                </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- page -->
                <div style="margin-top:20px;text-align:center">
                    <el-pagination
                        background
                        layout="total, prev, pager, next, sizes"
                        :page-sizes="[20,30,50,100]"
                        :total="total"
                        :page-size="query.limit"
                        @size-change="onSize"
                        @current-change="onCurrPage"
                        @prev-click="onCurrPage"
                        @next-click="onCurrPage"
                    >
                    </el-pagination>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { lists,del } from '../../../request/api/dict';
export default {
    data(){
        return {
            tableData: [],
            query:{typeCode:'',page:1,size:10},
            total:0 , //列表总数
            loading:false,
            isEdit:false ,//编辑状态
        }
    },
    watch:{
        $route(val ,old){
            //返回刷新
            if(val.name=='dict') {
                this.init()
            }
        }
    },
    created(){
        this.init()
    },

    methods:{
        init(){
            this.loading=true
            lists(this.query).then(res=>{
                this.tableData = res.data.list
                this.total = res.data.total
                this.loading=false
            })
        },
        handleDel(row){
            del({ids:[row.id]}).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                this.init()
                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
            })
        },

        //每页条数
        onSize(val){
            this.query.size=val
            this.init()
        },
        onCurrPage(val){
            this.query.page = val
            this.init()
        }

    }
}
</script>


<style scoped lang="less">


</style>

